h2 {
  font-size: 50px;
  font-weight: bold;
  color: #B57562;
}

.container {
  max-width: 1000px;
  margin: auto;
}

.app-logo {
  height: 200px;
  pointer-events: none;
}

.app-header {
  height: 200px;
  padding: 20px;
  background: url('./assets/header-tall.jpg');
  background-position: center;
  background-size: 100% 120px;
  background-repeat: no-repeat;
}

.links {
  position: absolute;
  right: 50px;
  top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  list-style: none;
  padding: 0;
}

.links li {
  padding: 15px;
}
.links a {
  text-decoration: none;
  color: #B57562;
  font-weight: 700;
}
.links a:hover {
  color: #444;
}

.split {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.split > div {
  flex: 1;
  padding: 30px;
}

.split-image {
  width: 100%;
}

.feed-wrapper {
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
    scrollbar-width: none; 
}
.container::-webkit-scrollbar { 
  display: none;  
  width: 0;  
  height: 0;
  background: transparent; 
}

.feed {
  margin: 0;
  list-style: none;
  padding: 0;
  width: 1400px;
}

.feed li {
  width: 200px;
  height: 200px;
  margin: 15px;
  background-position: center;
  background-size: cover;
  display: inline-block;
  box-shadow: 0px 3px 5px rgba(0,0,0,0.12);
}

.img1 { 
  background: url('./assets/feed/1.jpg');
}
.img2 { 
  background: url('./assets/feed/2.jpg');
}
.img3 { 
  background: url('./assets/feed/3.jpg');
}
.img4 { 
  background: url('./assets/feed/4.jpg');
}
.img5 { 
  background: url('./assets/feed/5.jpg');
}
.img6 { 
  background: url('./assets/feed/6.jpg');
}

.footer {
  background-color: #B57562;
  color: #ffffff;
  font-weight: 400;
  margin-top: 20px;
  padding: 20px;
  text-align: right;
}

@media screen and (max-width: 1065px) {
  .links {
    position:relative;
    right: 0px;
    top: 0px;
  }

  .app-header {
    padding-top: 0;
    background-size: auto 120px;
  }
}

@media screen and (max-width: 800px) {
  .split {
    flex-direction: column;
  }
  .order1 {
    order: 1;
  }
  .app-header {
    text-align: center;
  }
}
